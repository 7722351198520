$(document).ready(function() {  

    $('.tip').tipr({
         'speed': 600,
         'mode': 'below',
         'marginAbove': -5,
         'marginBelow': 0,          
         'space': 60
    });

    $('#ratingsbmbtn').on('click',function () {

        if ( $('select[name=seminardate]').val() == '' ) {
            $("#notPossibleModal").modal("show");
            return false;
        }

        if ( !$('input[name=ratecontent]:checked').length || !$('input[name=rateteacher]:checked').length || !$('input[name=rateorganisation]:checked').length || !$('input[name=ratenps]:checked').length ) {
            $("#notPossibleModal").modal("show");
            return false;
        }

        if ( $('input[name=ratelocation]').val() && !$('input[name=ratelocation]:checked').length ) {
            alert("ratelocation problem!");
            $("#notPossibleModal").modal("show");
            return false;
        }
        
    });
      
    $('#leafletIcon').hover(
    function(){ $('#leaflet').addClass('show') },
    function(){ $('#leaflet').removeClass('show') }
    )
    
    $('.bookmark ').hover(
    function(){ $('#leafletHint').addClass('show') },
    function(){ $('#leafletHint').removeClass('show') }
    )
     
     

	$('.bookmark').on("click",function(event) {
		that = this;
		if($(that).hasClass('addBookmark')) {
			$.get( "/bookmark/add/", { id: $(that).data("id") } )
  			.done(function( data ) {
					if(data.ok) {
						$(that).find('i').removeClass("fa-star-o").addClass("fa-star");
						$(that).removeClass("addBookmark").addClass("removeBookmark");
						$('#leaflet').empty();
						$('#leaflet').load('/bookmark/showBookmarks/');
                        $('#leafletHint').html('nicht mehr merken').addClass('show');
                        $('#bookmarkIcon').attr({
                            alt: 'nicht mehr merken',
                            title: 'nicht mehr merken'
                        });                        
					}
				}
			);
		} else {
			
			$.get( "/bookmark/remove/", { id: $(that).data("id") } )
  			.done(function( data ) {
					if(data.ok) {
						$(that).find('i').removeClass("fa-star").addClass("fa-star-o");
						$(that).removeClass("removeBookmark").addClass("addBookmark");
						$('#leaflet').empty();
						$('#leaflet').load('/bookmark/showBookmarks/');	
                        $('#leafletHint').html('Seminar merken').addClass('show');
                        $('#bookmarkIcon').attr({
                            alt: 'Seminar merken',
                            title: 'Seminar merken'
                        });                        					
					}
				}
			);
			
		}
		
    }); 

    // Select all links with hashes
    $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function(event) {
        // On-page links
        if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
        && 
        location.hostname == this.hostname
        ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            $('html, body').animate({
            scrollTop: target.offset().top
            }, 1000, function() {
            // Callback after animation
            // Must change focus!
            var $target = $(target);
            $target.focus();
            if ($target.is(":focus")) { // Checking if the target was focused
                return false;
            } else {
                $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
            };
            });
        }
        }
    });      


    // erste Checkbox der Terminliste selektieren
    $('.terminListe input:radio:first').click();
});  